import {FC} from 'react';
import Container from '@components/Container';
import Button from '@components/button/Button';
import {PrivateRoutes} from '@constants/Route';

const HomePage: FC = () => {
  const handleRedirect = (route: PrivateRoutes) => {
    window.location.href = route;
  };

  const WELCOME_CONTENT = (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-4">
        <div className="text-3xl/[38px] font-semibold">
          Welcome to Prime <span className="text-[#95C147]">Documents</span>
        </div>
        <div className="text-sm text-[#4B5565]">
          Explore our comprehensive range of services and take the hassle out of securing the necessary documents to keep your business on track.
        </div>
      </div>
      <div className="flex flex-col">
        <p className="text-lg text-[#121926] font-semibold">Order a Document</p>
        <p className="text-sm text-[#4B5565]">
          No account is required. Simply order the document you need and make the payment on a pay-as-you-go basis.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-8 w-full">
        <div className="flex flex-col items-start p-[20px_16px] gap-4 self-stretch border border-[#CDD5DF] rounded-lg w-60">
          <div className="flex flex-col">
            <p className="text-[#121926] font-semibold">Order a Company</p>
          </div>
          <Button mode="primary" text="Start Order" onClick={() => handleRedirect(PrivateRoutes.Company)} />
        </div>
        <div className="flex flex-col items-start p-[20px_16px] gap-4 self-stretch border border-[#CDD5DF] rounded-lg w-60">
          <div className="flex flex-col">
            <p className="text-[#121926] font-semibold">Order a Discretionary Trust</p>
          </div>
          <Button mode="primary" text="Start Order" onClick={() => handleRedirect(PrivateRoutes.DiscretionaryTrust)} />
        </div>
        <div className="flex flex-col items-start p-[20px_16px] gap-4 self-stretch border border-[#CDD5DF] rounded-lg w-60">
          <div className="flex flex-col">
            <p className="text-[#121926] font-semibold">Order a Unit Trust</p>
          </div>
          <Button mode="primary" text="Start Order" onClick={() => handleRedirect(PrivateRoutes.UnitTrust)} />
        </div>
      </div>
    </div>
  );

  // Removed temporarily
  // const SUBSCRIPTION_CONTENT = (
  //   <div className="flex flex-col gap-8">
  //     <div className="flex flex-col">
  //       <p className="text-[#121926] font-semibold text-lg">Subscription Plans</p>
  //       <p className="text-sm text-[#4B5565]">With a subscription, you'll benefit from a simplified payment structure.</p>
  //     </div>
  //     <div className="flex flex-row gap-4">
  //       <Button mode="default" text="Learn More" onClick={() => {}} />
  //       <Button
  //         mode="secondary"
  //         text="Contact Sales"
  //         onClick={() => {
  //           window.open('https://www.primecc.com.au/contact-us/', '_blank');
  //         }}
  //       />
  //     </div>
  //   </div>
  // );

  return (
    <div className="flex flex-col flex-1 gap-8 p-8 w-full h-full">
      <Container content={WELCOME_CONTENT} />
      {/* <Container content={SUBSCRIPTION_CONTENT} /> */}
    </div>
  );
};

export default HomePage;
